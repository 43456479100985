/* Estilos para a scrollbar */
::-webkit-scrollbar {
  width: 0px; /* Largura da scrollbar */
  background-color: transparent; /* Cor de fundo da scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: transparent; /* Cor da parte deslizante da scrollbar */
  border-radius: 0px; /* Bordas arredondadas */
}

::-webkit-scrollbar-thumb:hover {
  background-color: transparent; /* Cor da parte deslizante ao passar o mouse */
}

::-webkit-scrollbar-track {
  background: transparent; /* Cor de fundo da pista da scrollbar */
}

.theme-toggle {
  margin-top: -60px;
  background: none;
  border: none;
  color: white; /* Mude para 'black' se estiver no modo claro */
  font-size: 1.5rem;
  padding: 0rem 1rem;
  margin-left: auto; /* Alinha o botão à direita */
  transition: transform 0.5s ease; /* Suaviza a transição */
}

.theme-toggle:hover {
  transform: scale(1.2); /* Aumenta o tamanho do emoji durante a transição */
}
.navbar .logo {
  margin-top: -15px;
  font-size: 25px;
  width: 110px; /* Defina uma largura fixa */
  height: 100px;
  overflow: hidden;
  text-align: center;
  font-size: 20px;
  transition: font-family 0.5s ease; /* Transição suave para fonte e tamanho */
}


.theme-toggle.emoji-transition {
  transform: rotate(0.5turn); /* Aumenta o tamanho do emoji durante a transição */
}


body.dark .site-header {
  background-color: rgb(15, 15, 15);
  position: fixed !important;
  font-family: Whitney, sans-serif;
  font-size: 20px;
  font-weight: 700;
  margin-left: -10px;
  width: 100%;
  margin-top: -4rem;
  padding-top: 10px;
  height: 70px;
  z-index: 999;
}
body.light .site-header {
  background-color: rgb(255, 255, 255);
  position: fixed !important;
  font-family: Whitney, sans-serif;
  font-size: 20px;
  font-weight: 700;
  margin-left: -10px;
  width: 100%;
  margin-top: -4rem;
  padding-top: 10px;
  height: 70px;
  z-index: 999;
}

.navbar {
  display: flex;
  max-width: 80rem;
  justify-content: space-between;
  margin: 0 auto;
}

.menu {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
}
.menu2 {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
}
.navbar a {
  font-family: 'Inter-Bureau', sans-serif;
  font-size: 20px;
  font-weight: 500;
  display: block;
  padding: 1.3rem 1rem 1rem 1rem;
  text-decoration: none;
  color: white;
  transition: color 0.3s, transform 0.3s;
}

.navbar a:hover {
  color: #ff5100 !important; /* Força a aplicação do cinza claro */
  transform: scale(1.05) !important;
}
.navbar a.active::after {
  content: '';
  display: block;
  width: 6px;
  height: 6px;
  background-color: #868686;
  border-radius: 50%;
  margin: 0.2rem auto 0;
  transition: background-color 0.3s;
}

body.light .navbar a {
  color: #1b1b1b;
}

/* Estilos para o modo escuro */
body.dark .navbar a {
  color: white;
}

footer {
  
  max-width: 100vw;
  padding-top: 1rem;
  margin: 0 auto 2rem;
  text-align: center;
  font-family: 'Retro Land Mayhem', sans-serif;
  font-size: 18px;
  font-weight: 300;
  border-top: 1px solid #313131;
  margin-top: 5rem;
}

/*mobile*/
.menu-toggle.active span {
  transform: rotate(90deg) translateY(-8px); /* Gira e alinha */
}

/* Evita sobreposição entre spans */
.menu-toggle.active span:nth-child(1) {
  transform: rotate(90deg) translateY(-7px) translateX(16px);
}

.menu-toggle.active span:nth-child(2) {
  transform: rotate(90deg) translateY(0px) translateX(8px); /* Mantém posição central */
}

.menu-toggle.active span:nth-child(3) {
  transform: rotate(90deg) translateY(7px); /* Posiciona na parte inferior */
}
.menu-toggle {
  display: none;
  font-size: 2rem;
  background: none;
  border: none;
  color: white;
}
body.dark .menu-toggle span {
  display: block;
  width: 25px;
  height: 2px;
  margin: 5px auto;
  background-color: white;
  transition: transform 0.3s ease, opacity 0.3s ease;
}
body.light .menu-toggle span {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  background-color: #1b1b1b;
  transition: transform 0.3s ease, opacity 0.3s ease;
}
.menu-toggle.active span {
  transition: transform 0.5s, opacity 0.5s;
}

/* Estilos para a navbar no mobile */
@media (max-width: 768px) {
  .site-header {
    background-color: rgb(15, 15, 15);
    position: relative !important;
    font-family: Whitney, sans-serif;
    font-size: 20px;
    font-weight: 700;
    margin-left: 6px;
    width: 100%;
    margin-top: 0rem;
    padding-top: 0;
    height: 0px;
    z-index: 999;
  }
  .navbar a.active::after {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    background-color: #868686;
    border-radius: 50%;
    margin: -0.7rem -1rem 0;
    transition: background-color 0.3s;
  }
/* Estilos do botão de tema */
  .menu2 {
    margin-bottom: 1rem;
    width: 100%;
  }
  .menu2 a.active{
    padding: 1rem;
    width: 100%;
  }
  .theme-toggle {
    margin-top: 0px;
    margin-right: 11px;
    background: none;
    border: none;
    color: white; /* Mude para 'black' se estiver no modo claro */
    font-size: 1.5rem;
    padding: 0.5rem 0rem;
    margin-left: auto; /* Alinha o botão à direita */
  }
  .navbar {
    flex-direction: column;
    align-items: flex-start;
  }
  .navbar .logo{
    text-align: left;
    margin-top: 10px;
    margin-bottom: -80px;
    margin-left: 5px;
    width: 150px;
  }
  .menu {
    display: none;
    flex-direction: column;
    width: 100%;
  }

  body.dark .menu.active {
    background-color: rgb(15, 15, 15);
    height: 200px;
    display: flex;
  }
  body.light .menu.active {
    background-color: rgb(255, 255, 255);
    height: 200px;
    display: flex;
  }
  .navbar a {
    padding: 1rem;
    width: 50%;
  }

  .menu-toggle {
    margin-right: 10px;
    display: block;
    margin-left: auto;
    margin-top: -38px;
    margin-bottom: 5px;
    align-items: left;
  }
  
}


.scroll-indicator {
  position: fixed;
  bottom: 20px; /* Ajuste a posição vertical conforme necessário */
  left: 10%;
  transform: translateX(-50%);
  font-size: 20px; /* Tamanho da seta */
  color: #a7a7a7; /* Cor da seta, mude conforme sua paleta de cores */
  animation: bounce 1s infinite, fade 0.5s infinite; /* Combina ambas as animações */
}

@keyframes fade {
    0% {
        opacity:  0.7 /* Começa totalmente opaco */
    }
    50% {
        opacity: 0.5; /* Diminui para meio opaco */
    }
    100% {
        opacity: 0.7; /* Retorna para totalmente opaco */
    }
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px); /* Levanta a seta */
  }
  60% {
    transform: translateY(-5px); /* Levanta um pouco */
  }
}
