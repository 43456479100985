.Projects {
    margin-bottom: 2rem;
    max-width: 60rem;
    margin: 0 auto;
    font-family: var(--font-sans);
    margin-top: 4rem;
    line-height: 1.3;
    font-family: Whitney, sans-serif;
    font-weight: 800;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.proj-title {
    margin-bottom: 3rem;
    width: 100%;
    height: 10rem;
}

.proj-title h1 {
    font-family: 'Orbitron', sans-serif;
    font-size: 65px;
    margin-bottom: 0.2rem;
    animation: fade 0.5s infinite; /* Adiciona a animação de opacidade */
}
@keyframes fade {
    0% {
        opacity:  0.7 /* Começa totalmente opaco */
    }
    50% {
        opacity: 0.5; /* Diminui para meio opaco */
    }
    100% {
        opacity: 0.7; /* Retorna para totalmente opaco */
    }
}

body.dark .proj-title h2 {
    font-family: 'Inter-Bureau', sans-serif;
    color: rgb(255, 255, 255);
    font-weight: 500;
    font-size: 25px;
    margin-top: 0;
}
body.light .proj-title h2 {
    font-family: 'Inter-Bureau', sans-serif;
    color: rgb(0, 0, 0);
    font-weight: 500;
    font-size: 25px;
    margin-top: 0;
}
.project-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    width: 100%;
}

.proj-subtitle {
    position: relative;
    overflow: hidden;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    border: 1px solid rgb(41, 41, 41);
    box-shadow: inset 0 0 25px #adadad;
    text-decoration: none;
    transition: transform 0.5s ease; /* Transição suave */
}

.proj-video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
    z-index: -1;
    opacity: 0.4;
}

.proj-subtitle:hover {
    transform: scale(1.02); /* Efeito de zoom no hover */
}

.proj-subtitle::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: inherit;
    background-size: cover;
    background-position: center;
    border: 1px solid rgb(41, 41, 41);
    box-shadow: inset 0 0 25px #adadad;
    z-index: 0;
}

.proj-subtitle::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
    border-radius: 15px;
}

.proj-text {
    position: relative;
    color: rgb(177, 177, 177);
    font-size: 25px;
    font-weight: 500;
    z-index: 2;
    text-align: center;
}

.proj-subtitle:hover .proj-text {
    transition: color 0.5s ease;
    color: white;
}

.pagination {
    font-weight: 500;
    margin-top: 5rem;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.pagination-btn {
    font-family: 'Courier New', Courier, monospace;
    font-weight: 800;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0.3rem; 
    border-radius: 50%;
    background-color: #333;
    color: #a8a8a8;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.pagination-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.pagination-btn:not(:disabled):hover {
    background-color: #555;
}

@media (max-width: 768px) {
    .proj-title {
        width: 100%;
        height: 14rem;
        text-align: center;
    }
    .project-grid {
        grid-template-columns: 1fr;
    }
    .pagination-btn {
        font-family: 'Courier New', Courier, monospace;
        font-weight: 800;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 0.4rem; 
        border-radius: 50%;
        background-color: #333;
        color: #a8a8a8;
        border: none;
        font-size: 2.2rem;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }
}
