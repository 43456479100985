body.dark .slider-container {
    position: relative; /* Mantenha como relative */
    width: 100vw; /* Largura total da viewport */
    left: calc(50% - 50vw); /* Move o slider para a esquerda */
    top: 0; /* Alinha ao topo do seu contêiner pai */
    overflow: hidden; /* Oculta os itens que estão fora do contêiner */
    background-color: transparent; /* Ajuste conforme necessário */
}

body.light .slider-container {
    position: relative; /* Mantenha como relative */
    width: 100vw; /* Largura total da viewport */
    left: calc(50% - 50vw); /* Move o slider para a esquerda */
    top: 0; /* Alinha ao topo do seu contêiner pai */
    overflow: hidden; /* Oculta os itens que estão fora do contêiner */
    background-color: rgb(243, 243, 243); /* Ajuste conforme necessário */
}
.slider {
    display: flex; /* Alinha os itens em uma linha */
    animation: slide 25s linear infinite; /* Animação contínua */
    transition: transform 0.5s ease-in-out; /* Suaviza o movimento */
    width: 100%; /* Garante que o slider ocupe 100% do contêiner */
}



.slider.paused {
    animation-play-state: paused; /* Pausa a animação quando o mouse está sobre o slider */
}

@keyframes slide {
    0% {
        transform: translateX(0); /* Começa no início */
    }
    100% {
        transform: translateX(-50%); /* Move para a esquerda pela metade da largura total */
    }
}


body.dark .slide, 
body.light .slide {
    flex: 0 0 auto; /* Impede que os cards se estiquem ou encolham */
    width: 150px; /* Largura de cada card */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 65px; /* Altura dos cards */
    color: #808080;
    background: transparent; /* Cor de fundo dos cards */
    margin-right: 10px; /* Espaçamento entre os cards */
}

body.light .slide {
    color: #1b1b1b; /* Cor do texto no modo claro */
}
h3 {
    font-weight: 500;
}

body.dark .white-icon {
    width: 30px; /* Ajuste a largura conforme necessário */
    height: auto; /* Mantém a proporção do SVG */
    margin-right: 8px; /* Espaçamento entre o ícone e o texto */
    filter: grayscale(100); /* Escurece o ícone no modo escuro */
}
body.light .white-icon {
    width: 30px; /* Ajuste a largura conforme necessário */
    height: auto; /* Mantém a proporção do SVG */
    margin-right: 8px; /* Espaçamento entre o ícone e o texto */
    filter: brightness(0) invert(0);
}
