.content {
    margin-bottom: 2rem;
    max-width: 70rem;
    margin: 0 auto;
    font-family: var(--font-sans);
    margin-top: 4rem;
    line-height: 1.3;
    font-family: Whitney, sans-serif;
    font-weight: 800;
    display: flex;
    flex-direction: column; /* Muda para coluna por padrão */
    align-items: center; /* Centraliza o conteúdo */
}

.title-container {
    z-index: 2;
    flex-direction: column; /* Coloca os itens em coluna em telas menores */
    align-items: center; /* Centraliza o conteúdo no eixo horizontal */
    text-align: center; /* Centraliza o texto no contêiner */
    padding-top: 2rem;
    margin-top: 8rem;
    padding-bottom: 0rem;
    height: 650px;
    display: flex; /* Usando flexbox para manter título e imagem lado a lado */
    width: 100%; /* Largura total para o contêiner */
    flex-wrap: wrap; /* Permite que os itens quebrem para a próxima linha se necessário */
}

.title {
    max-width: 100rem;
    flex: 1; /* Permite que o título use o espaço disponível */
    margin-right: 1rem; /* Espaço entre o título e a imagem */
}


.skills {
    max-width: 100rem;
    width: 100%; /* Garante que a seção skills ocupe 100% da largura disponível */
    margin-top: 3.3rem; /* Adiciona espaço entre o título e a seção de habilidades */
    padding: 1rem; /* Adiciona preenchimento interno */
    padding-bottom: 6rem;
    text-align: center; /* Alinha o texto à esquerda */
}
body.dark .skills .title {
    font-family: 'Orbitron', sans-serif;
    position: relative;
    top: 120px;
    left: -320px;
    background: white;
    -webkit-background-clip: text; /* Clipa o fundo para o texto no Safari */
    -webkit-text-fill-color: transparent; /* Torna o preenchimento do texto transparente */
    font-size: 60px;
    animation: fade 0.5s infinite; /* Adiciona a animação de opacidade */
}
body.light .skills .title {
    font-family: 'Orbitron', sans-serif;
    position: relative;
    top: 120px;
    left: -320px;
    background: rgb(0, 0, 0);
    -webkit-background-clip: text; /* Clipa o fundo para o texto no Safari */
    -webkit-text-fill-color: transparent; /* Torna o preenchimento do texto transparente */
    font-size: 60px;
    animation: fade 0.5s infinite; /* Adiciona a animação de opacidade */
}
body.dark .skills .title-en {
    font-family: 'Orbitron', sans-serif;
    position: relative;
    top: 120px;
    left: -440px;
    background: white;
    -webkit-background-clip: text; /* Clipa o fundo para o texto no Safari */
    -webkit-text-fill-color: transparent; /* Torna o preenchimento do texto transparente */
    font-size: 60px;
    animation: fade 0.5s infinite; /* Adiciona a animação de opacidade */
}
body.light .skills .title-en {
    font-family: 'Orbitron', sans-serif;
    position: relative;
    top: 120px;
    left: -440px;
    background: rgb(0, 0, 0);
    -webkit-background-clip: text; /* Clipa o fundo para o texto no Safari */
    -webkit-text-fill-color: transparent; /* Torna o preenchimento do texto transparente */
    font-size: 60px;
    animation: fade 0.5s infinite; /* Adiciona a animação de opacidade */
}
@keyframes fade {
    0% {
        opacity:  0.7 /* Começa totalmente opaco */
    }
    50% {
        opacity: 0.5; /* Diminui para meio opaco */
    }
    100% {
        opacity: 0.7; /* Retorna para totalmente opaco */
    }
}
body.dark .skills .carrousel {
    background-color: rgba(15, 15, 15, 1);
    height: 1000vh; /* Mantém a altura total do carrossel */
}
body.light .skills .carrousel {
    background-color: rgb(255, 255, 255);
    height: 1000vh; /* Mantém a altura total do carrossel */
}
.skills .contentContainer {
    height: 500px; 
    margin-bottom: 20rem;
    top: 0;
    align-items: center;
    justify-content: flex-start;
}
.defaultHeight {
    height: 1000vh;
}
.afterHeight {
    height: 850px;
}
.sticky {
    position: sticky;
    top: 120px; /* Ou a altura que você deseja */
    z-index: 10; /* Certifique-se de que ela está acima de outros elementos */
}

.unsticky {
    height:100px!important;
    position: relative; /* Deixa de ser sticky */
    top: auto; /* Pode ajustar a posição se necessário */
}

body.dark .projetos-ir {
    text-decoration: none;
    font-size:18px;
    top: -150px;
    left: -830px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 170px;
    padding: 10px 20px;
    border: 1px solid #fff;
    border-radius: 50px;
    background-color: #1a1a1a;
    color: #fff;
    font-weight: 200;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    transition: background-color 0.3s ease;
}
body.light .projetos-ir {
    text-decoration: none;
    font-size:18px;
    top: -250px;
    left: -100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 170px;
    padding: 10px 20px;
    border: 1px solid #000000;
    border-radius: 50px;
    background-color: #1a1a1a;
    color: #fff;
    font-weight: 200;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    transition: background-color 0.3s ease;
}


/* Estilo do texto e da seta */
.projetos-ir span {
    transition: transform 0.3s ease;
}

.projetos-ir .text {
    position: relative;
    z-index: 1;
}

.projetos-ir .arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    left: 14px;
    width: 60px;
    height: 40px;
    background-color: #333;
    border-radius: 39px;
    transition: transform 0.3s ease, background-color 0.5s ease, color 0.5s ease;
    position: relative;
    z-index: 1;
}

/* Efeito de hover */
.projetos-ir:hover {
    background-color: #333;
}

.projetos-ir:hover .text {
    transform: translateX(80px);
}

.projetos-ir:hover .arrow {
    color: #333;
    background-color: white;
    transform: translateX(-130px);
}


body.dark .contact-ir {
    text-decoration: none;
    font-size:18px;
    top: -150px;
    left: -830px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 170px;
    padding: 10px 20px;
    border: 1px solid #fff;
    border-radius: 50px;
    background-color: #1a1a1a;
    color: #fff;
    font-weight: 200;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    transition: background-color 0.3s ease;
}
body.light .contact-ir {
    text-decoration: none;
    font-size:18px;
    top: -250px;
    left: -100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 170px;
    padding: 10px 20px;
    border: 1px solid #000000;
    border-radius: 50px;
    background-color: #1a1a1a;
    color: #fff;
    font-weight: 200;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    transition: background-color 0.3s ease;
}


/* Estilo do texto e da seta */
.contact-ir span {
    transition: transform 0.3s ease;
}

.contact-ir .text {
    position: relative;
    z-index: 1;
}

.contact-ir .arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    left: 14px;
    width: 60px;
    height: 40px;
    background-color: #333;
    border-radius: 39px;
    transition: transform 0.3s ease, background-color 0.5s ease, color 0.5s ease;
    position: relative;
    z-index: 1;
}

/* Efeito de hover */
.contact-ir:hover {
    background-color: #333;
}

.contact-ir:hover .text {
    transform: translateX(80px);
}

.contact-ir:hover .arrow {
    color: #333;
    background-color: white;
    transform: translateX(-130px);
}

.carousel {
    position: relative;
    top: 120px;
    display: flex;
    overflow: hidden; /* Permite rolagem horizontal */
    width: 100%; /* O carrossel ocupa 100% da largura do contêiner pai */
    height: 750px; /* Altura do carrossel */
}

.indicator{
    font-weight: 400;
    position: relative;
    font-size: 10px;
    top: -5px;
    left: -700px;
    animation: fade 0.5s infinite; /* Combina ambas as animações */
}

@keyframes fade {
    0% {
        opacity:  0.7 /* Começa totalmente opaco */
    }
    50% {
        opacity: 0.5; /* Diminui para meio opaco */
    }
    100% {
        opacity: 0.7; /* Retorna para totalmente opaco */
    }
}
.carousel-item {
    min-width: 1800px; /* Aumente a largura mínima de cada item */
    height: 690px; /* Para ocupar a altura total do carrossel */
    background: transparent; /* Gradiente como fundo */
    color: white; /* Cor do texto */
    margin: 40px; /* Aumente o espaçamento entre os itens */
    display: flex;
    flex-direction: column; /* Muda a direção para coluna */
    justify-content: center;
    align-items: center;
    font-size: 48px; /* Tamanho da fonte */
    opacity: 0; /* Começa invisível */
    transform: translateY(20px); /* Começa abaixo */
    transition: opacity 0.5s ease, transform 0.5s ease; /* Define a animação */
    
}
.carousel-item.show {
    opacity: 1; /* Torna visível */
    transform: translateY(0); /* Move para a posição original */
}
body.dark .dev01{
    position: relative;
    left: -827px;
    top: -100px;
    font-family: 'Orbitron', sans-serif;
    color: rgba(15, 15, 15, 1);
    font-size: 200px;
    text-shadow: 1px 1px 0 #ff5100, -1px -1px 0 #ff5100, 1px -1px 0 #ff5100, -1px 1px 0 #ff5100;
    z-index: 0;
}
body.light .dev01{
    position: relative;
    left: -827px;
    top: -100px;
    font-family: 'Orbitron', sans-serif;
    color: rgb(255, 255, 255);
    font-size: 200px;
    text-shadow: 1px 1px 0 #ff5100, -1px -1px 0 #ff5100, 1px -1px 0 #ff5100, -1px 1px 0 #ff5100;
    z-index: 0;
}
body.dark .dev{
    position: relative;
    z-index: 1;
    left: -690px;
    top: -220px;
    font-family: 'Orbitron', sans-serif;
    color: rgb(255, 255, 255);
    font-size: 50px;
}
body.light .dev{
    position: relative;
    z-index: 1;
    left: -690px;
    top: -220px;
    font-family: 'Orbitron', sans-serif;
    color: rgb(0, 0, 0);
    font-size: 50px;
}
body.dark .titletext{
    position: relative;
    text-align: left;
    width: 700px; /* Mantenha como 100% para ocupar o contêiner pai */
    font-family: 'Inter-Bureau', sans-serif;
    color: rgb(182, 182, 182);
    font-weight: 100;
    top: -70px;
    left: -590px;
    font-size: 20px;
    height: auto; /* Permita que a altura se ajuste ao conteúdo */
    overflow: visible; /* Garante que o texto não seja cortado */
}
body.light .titletext{
    position: relative;
    text-align: left;
    width: 700px; /* Mantenha como 100% para ocupar o contêiner pai */
    font-family: 'Inter-Bureau', sans-serif;
    color: rgb(77, 77, 77);
    font-weight: 100;
    top: -70px;
    left: -590px;
    font-size: 20px;
    height: auto; /* Permita que a altura se ajuste ao conteúdo */
    overflow: visible; /* Garante que o texto não seja cortado */
}

body.dark .description {
    position: relative;
    text-align: left;
    top: -175px;
    left: -595px;
    width: 680px; /* Mantenha como 100% para ocupar o contêiner pai */
    font-family: 'Inter-Bureau', sans-serif;
    color: rgb(255, 255, 255);
    font-weight: 300;
    font-size: 20px;
    height: auto; /* Permita que a altura se ajuste ao conteúdo */
    overflow: visible; /* Garante que o texto não seja cortado */
}
body.light .description {
    position: relative;
    text-align: left;
    top: -175px;
    left: -595px;
    width: 680px; /* Mantenha como 100% para ocupar o contêiner pai */
    font-family: 'Inter-Bureau', sans-serif;
    color: rgb(0, 0, 0);
    font-weight: 300;
    font-size: 20px;
    height: auto; /* Permita que a altura se ajuste ao conteúdo */
    overflow: visible; /* Garante que o texto não seja cortado */
}



/*dev02*/
body.dark .dev02{
    position: relative;
    left: -780px;
    top: -100px;
    font-family: 'Orbitron', sans-serif;
    color: rgba(15, 15, 15, 1);
    font-size: 200px;
    text-shadow: 1px 1px 0 #ff5100, -1px -1px 0 #ff5100, 1px -1px 0 #ff5100, -1px 1px 0 #ff5100;
    z-index: 0;
}
body.light .dev02{
    position: relative;
    left: -780px;
    top: -100px;
    font-family: 'Orbitron', sans-serif;
    color: rgb(255, 255, 255);
    font-size: 200px;
    text-shadow: 1px 1px 0 #ff5100, -1px -1px 0 #ff5100, 1px -1px 0 #ff5100, -1px 1px 0 #ff5100;
    z-index: 0;
}
body.dark .dev2{
    position: relative;
    z-index: 1;
    left: -770px;
    top: -220px;
    font-family: 'Orbitron', sans-serif;
    color: rgb(255, 255, 255);
    font-size: 50px;
}
body.light .dev2{
    position: relative;
    z-index: 1;
    left: -770px;
    top: -220px;
    font-family: 'Orbitron', sans-serif;
    color: rgb(0, 0, 0);
    font-size: 50px;
}
body.dark .titletext2{
    position: relative;
    text-align: left;
    width: 700px; /* Mantenha como 100% para ocupar o contêiner pai */
    font-family: 'Inter-Bureau', sans-serif;
    color: rgb(182, 182, 182);
    font-weight: 100;
    top: -70px;
    left: -590px;
    font-size: 20px;
    height: auto; /* Permita que a altura se ajuste ao conteúdo */
    overflow: visible; /* Garante que o texto não seja cortado */
}
body.light .titletext2{
    position: relative;
    text-align: left;
    width: 700px; /* Mantenha como 100% para ocupar o contêiner pai */
    font-family: 'Inter-Bureau', sans-serif;
    color: rgb(77, 77, 77);
    font-weight: 100;
    top: -70px;
    left: -590px;
    font-size: 20px;
    height: auto; /* Permita que a altura se ajuste ao conteúdo */
    overflow: visible; /* Garante que o texto não seja cortado */
}

body.dark .description2 {
    position: relative;
    text-align: left;
    top: -175px;
    left: -595px;
    width: 680px; /* Mantenha como 100% para ocupar o contêiner pai */
    font-family: 'Inter-Bureau', sans-serif;
    color: rgb(255, 255, 255);
    font-weight: 300;
    font-size: 20px;
    height: auto; /* Permita que a altura se ajuste ao conteúdo */
    overflow: visible; /* Garante que o texto não seja cortado */
}
body.light .description2 {
    position: relative;
    text-align: left;
    top: -175px;
    left: -595px;
    width: 680px; /* Mantenha como 100% para ocupar o contêiner pai */
    font-family: 'Inter-Bureau', sans-serif;
    color: rgb(0, 0, 0);
    font-weight: 300;
    font-size: 20px;
    height: auto; /* Permita que a altura se ajuste ao conteúdo */
    overflow: visible; /* Garante que o texto não seja cortado */
}


/*dev03*/
body.dark .dev03{
    position: relative;
    left: -780px;
    top: -100px;
    font-family: 'Orbitron', sans-serif;
    color: rgba(15, 15, 15, 1);
    font-size: 200px;
    text-shadow: 1px 1px 0 #ff5100, -1px -1px 0 #ff5100, 1px -1px 0 #ff5100, -1px 1px 0 #ff5100;
    z-index: 0;
}
body.light .dev03{
    position: relative;
    left: -780px;
    top: -100px;
    font-family: 'Orbitron', sans-serif;
    color: rgb(255, 255, 255);
    font-size: 200px;
    text-shadow: 1px 1px 0 #ff5100, -1px -1px 0 #ff5100, 1px -1px 0 #ff5100, -1px 1px 0 #ff5100;
    z-index: 0;
}
body.dark .dev3{
    position: relative;
    z-index: 1;
    left: -770px;
    top: -220px;
    font-family: 'Orbitron', sans-serif;
    color: rgb(255, 255, 255);
    font-size: 50px;
}
body.light .dev3{
    position: relative;
    z-index: 1;
    left: -770px;
    top: -220px;
    font-family: 'Orbitron', sans-serif;
    color: rgb(0, 0, 0);
    font-size: 50px;
}
body.dark .titletext3{
    position: relative;
    text-align: left;
    width: 700px; /* Mantenha como 100% para ocupar o contêiner pai */
    font-family: 'Inter-Bureau', sans-serif;
    color: rgb(182, 182, 182);
    font-weight: 100;
    top: -70px;
    left: -590px;
    font-size: 20px;
    height: auto; /* Permita que a altura se ajuste ao conteúdo */
    overflow: visible; /* Garante que o texto não seja cortado */
}
body.light .titletext3{
    position: relative;
    text-align: left;
    width: 700px; /* Mantenha como 100% para ocupar o contêiner pai */
    font-family: 'Inter-Bureau', sans-serif;
    color: rgb(77, 77, 77);
    font-weight: 100;
    top: -70px;
    left: -590px;
    font-size: 20px;
    height: auto; /* Permita que a altura se ajuste ao conteúdo */
    overflow: visible; /* Garante que o texto não seja cortado */
}

body.dark .description3 {
    position: relative;
    text-align: left;
    top: -175px;
    left: -595px;
    width: 680px; /* Mantenha como 100% para ocupar o contêiner pai */
    font-family: 'Inter-Bureau', sans-serif;
    color: rgb(255, 255, 255);
    font-weight: 300;
    font-size: 20px;
    height: auto; /* Permita que a altura se ajuste ao conteúdo */
    overflow: visible; /* Garante que o texto não seja cortado */
}
body.light .description3 {
    position: relative;
    text-align: left;
    top: -175px;
    left: -595px;
    width: 680px; /* Mantenha como 100% para ocupar o contêiner pai */
    font-family: 'Inter-Bureau', sans-serif;
    color: rgb(0, 0, 0);
    font-weight: 300;
    font-size: 20px;
    height: auto; /* Permita que a altura se ajuste ao conteúdo */
    overflow: visible; /* Garante que o texto não seja cortado */
}


/*dev04*/
body.dark .dev04{
    position: relative;
    left: -787px;
    top: -100px;
    font-family: 'Orbitron', sans-serif;
    color: rgba(15, 15, 15, 1);
    font-size: 200px;
    text-shadow: 1px 1px 0 #ff5100, -1px -1px 0 #ff5100, 1px -1px 0 #ff5100, -1px 1px 0 #ff5100;
    z-index: 0;
}
body.light .dev04{
    position: relative;
    left: -787px;
    top: -100px;
    font-family: 'Orbitron', sans-serif;
    color: rgb(255, 255, 255);
    font-size: 200px;
    text-shadow: 1px 1px 0 #ff5100, -1px -1px 0 #ff5100, 1px -1px 0 #ff5100, -1px 1px 0 #ff5100;
    z-index: 0;
}
body.dark .dev4{
    position: relative;
    z-index: 1;
    left: -810px;
    top: -220px;
    font-family: 'Orbitron', sans-serif;
    color: rgb(255, 255, 255);
    font-size: 50px;
}
body.light .dev4{
    position: relative;
    z-index: 1;
    left: -810px;
    top: -220px;
    font-family: 'Orbitron', sans-serif;
    color: rgb(0, 0, 0);
    font-size: 50px;
}
body.dark .titletext4{
    position: relative;
    text-align: left;
    width: 700px; /* Mantenha como 100% para ocupar o contêiner pai */
    font-family: 'Inter-Bureau', sans-serif;
    color: rgb(182, 182, 182);
    font-weight: 100;
    top: -70px;
    left: -590px;
    font-size: 20px;
    height: auto; /* Permita que a altura se ajuste ao conteúdo */
    overflow: visible; /* Garante que o texto não seja cortado */
}
body.light .titletext4{
    position: relative;
    text-align: left;
    width: 700px; /* Mantenha como 100% para ocupar o contêiner pai */
    font-family: 'Inter-Bureau', sans-serif;
    color: rgb(77, 77, 77);
    font-weight: 100;
    top: -70px;
    left: -590px;
    font-size: 20px;
    height: auto; /* Permita que a altura se ajuste ao conteúdo */
    overflow: visible; /* Garante que o texto não seja cortado */
}

body.dark .description4 {
    position: relative;
    text-align: left;
    top: -175px;
    left: -595px;
    width: 680px; /* Mantenha como 100% para ocupar o contêiner pai */
    font-family: 'Inter-Bureau', sans-serif;
    color: rgb(255, 255, 255);
    font-weight: 300;
    font-size: 20px;
    height: auto; /* Permita que a altura se ajuste ao conteúdo */
    overflow: visible; /* Garante que o texto não seja cortado */
}
body.light .description4 {
    position: relative;
    text-align: left;
    top: -175px;
    left: -595px;
    width: 680px; /* Mantenha como 100% para ocupar o contêiner pai */
    font-family: 'Inter-Bureau', sans-serif;
    color: rgb(0, 0, 0);
    font-weight: 300;
    font-size: 20px;
    height: auto; /* Permita que a altura se ajuste ao conteúdo */
    overflow: visible; /* Garante que o texto não seja cortado */
}

/*dev05*/
body.dark .dev05{
    position: relative;
    left: -777px;
    top: -100px;
    font-family: 'Orbitron', sans-serif;
    color: rgba(15, 15, 15, 1);
    font-size: 200px;
    text-shadow: 1px 1px 0 #ff5100, -1px -1px 0 #ff5100, 1px -1px 0 #ff5100, -1px 1px 0 #ff5100;
    z-index: 0;
}
body.light .dev05{
    position: relative;
    left: -777px;
    top: -100px;
    font-family: 'Orbitron', sans-serif;
    color: rgb(255, 255, 255);
    font-size: 200px;
    text-shadow: 1px 1px 0 #ff5100, -1px -1px 0 #ff5100, 1px -1px 0 #ff5100, -1px 1px 0 #ff5100;
    z-index: 0;
}
body.dark .dev5{
    position: relative;
    z-index: 1;
    left: -820px;
    top: -220px;
    font-family: 'Orbitron', sans-serif;
    color: rgb(255, 255, 255);
    font-size: 50px;
}
body.light .dev5{
    position: relative;
    z-index: 1;
    left: -820px;
    top: -220px;
    font-family: 'Orbitron', sans-serif;
    color: rgb(0, 0, 0);
    font-size: 50px;
}
body.dark .titletext5{
    position: relative;
    text-align: left;
    width: 700px; /* Mantenha como 100% para ocupar o contêiner pai */
    font-family: 'Inter-Bureau', sans-serif;
    color: rgb(182, 182, 182);
    font-weight: 100;
    top: -70px;
    left: -590px;
    font-size: 20px;
    height: auto; /* Permita que a altura se ajuste ao conteúdo */
    overflow: visible; /* Garante que o texto não seja cortado */
}
body.light .titletext5{
    position: relative;
    text-align: left;
    width: 700px; /* Mantenha como 100% para ocupar o contêiner pai */
    font-family: 'Inter-Bureau', sans-serif;
    color: rgb(77, 77, 77);
    font-weight: 100;
    top: -70px;
    left: -590px;
    font-size: 20px;
    height: auto; /* Permita que a altura se ajuste ao conteúdo */
    overflow: visible; /* Garante que o texto não seja cortado */
}

body.dark .description5 {
    position: relative;
    text-align: left;
    top: -175px;
    left: -595px;
    width: 680px; /* Mantenha como 100% para ocupar o contêiner pai */
    font-family: 'Inter-Bureau', sans-serif;
    color: rgb(255, 255, 255);
    font-weight: 300;
    font-size: 20px;
    height: auto; /* Permita que a altura se ajuste ao conteúdo */
    overflow: visible; /* Garante que o texto não seja cortado */
}
body.light .description5 {
    position: relative;
    text-align: left;
    top: -175px;
    left: -595px;
    width: 680px; /* Mantenha como 100% para ocupar o contêiner pai */
    font-family: 'Inter-Bureau', sans-serif;
    color: rgb(0, 0, 0);
    font-weight: 300;
    font-size: 20px;
    height: auto; /* Permita que a altura se ajuste ao conteúdo */
    overflow: visible; /* Garante que o texto não seja cortado */
}



body.dark .history-line {
    display: flex;
    justify-content: center; /* Centraliza os pontos */
    align-items: center;
    position: relative;
    width: 100vw; /* Largura total da tela */
    left: 50%;
    transform: translateX(-50%);
    border-top: 2px dotted rgba(255, 255, 255, 0.5);
    padding-top: 0px;
    margin-top: 30px;
    gap: 250px; /* Ajusta o espaçamento entre os pontos */
    
}
body.light .history-line {
    display: flex;
    justify-content: center; /* Centraliza os pontos */
    align-items: center;
    position: relative;
    width: 100vw; /* Largura total da tela */
    left: 50%;
    transform: translateX(-50%);
    border-top: 2px dotted rgba(0, 0, 0, 0.5);
    padding-top: 0px;
    margin-top: 30px;
    gap: 250px; /* Ajusta o espaçamento entre os pontos */
    
}

body.dark .history-point {
    position: relative;
    top: -9px;
    width: 15px;
    height: 15px;
    background-color: rgb(95, 95, 95);
    border-radius: 50%;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}
body.light .history-point {
    position: relative;
    top: -9px;
    width: 15px;
    height: 15px;
    background-color: rgb(136, 136, 136);
    border-radius: 50%;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

body.dark .history-point.active {
    background-color: rgba(255, 255, 255, 1);
}
body.light .history-point.active {
    background-color: rgb(0, 0, 0);
}
body.dark .history-point.active .slide-number2 {
    color: rgba(255, 255, 255, 1);
}
body.light .history-point.active .slide-number2 {
    color: rgb(0, 0, 0);
}
body.dark .history-point.active .slide-number {
    text-shadow: 1px 1px 0 #ff5100, -1px -1px 0 #ff5100, 1px -1px 0 #ff5100, -1px 1px 0 #ff5100;
}
body.light .history-point.active .slide-number {
    color: white;
    text-shadow: 1px 1px 0 #ff5100, -1px -1px 0 #ff5100, 1px -1px 0 #ff5100, -1px 1px 0 #ff5100;
}

body.dark .slide-number {
    position: absolute;
    font-family: 'Orbitron', sans-serif;
    color: rgba(15, 15, 15, 1);
    font-size: 45px;
    text-shadow: 1px 1px 0 #8f2e01, -1px -1px 0 #8f2e01, 1px -1px 0 #8f2e01, -1px 1px 0 #8f2e01;
    top: -100px;
    left: -50px;
    text-align: center;
    white-space: nowrap;
}
body.light .slide-number {
    position: absolute;
    font-family: 'Orbitron', sans-serif;
    color: rgb(255, 255, 255);
    font-size: 45px;
    text-shadow: 1px 1px 0 #8f2e01, -1px -1px 0 #8f2e01, 1px -1px 0 #8f2e01, -1px 1px 0 #8f2e01;
    top: -100px;
    left: -50px;
    text-align: center;
    white-space: nowrap;
}
.slide-number2 {
    position: absolute;
    font-family: 'Orbitron', sans-serif;
    color: rgb(95, 95, 95);
    font-size: 20px;
    top: -50px;
    left: -50px;
    text-align: center;
    white-space: nowrap;
}


.experience {
    max-width: 100rem;
    width: 100%;
    margin-top: 8rem;
    padding: 1rem;
    margin-bottom: 15rem;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Alinha itens no início do contêiner */
    position: relative;
}

.experience .title {
    font-family: 'Orbitron', sans-serif;
    color: #ff5100;
    font-size: 65px; /* Tamanho responsivo */
    margin: 1rem 30rem;
    width: 500px;
}
body.dark .experience .about {
    font-weight: 400;
    font-family: 'Inter-Bureau', sans-serif;
    color: #8b8b8b;
    font-size: 25px; /* Tamanho responsivo */
    margin: -24rem 30rem;
    margin-bottom: -5rem;
    width: 500px;
}
body.light .experience .about {
    font-weight: 400;
    font-family: 'Inter-Bureau', sans-serif;
    color: #8b8b8b;
    font-size: 25px; /* Tamanho responsivo */
    margin: -24rem 30rem;
    margin-bottom: -5rem;
    width: 500px;
}


.experience .cards {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 2rem;
    width: 100vw; /* Ocupa a largura total da viewport */
    position: relative; /* Mantém a posição relativa */
    left: 50%; /* Centraliza o contêiner */
    transform: translateX(-50%); /* Ajuste para centralizar */
}


.experience .surname {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
    margin-top: -3rem;
    animation: fade2 1s infinite; /* Adiciona a animação de opacidade */
}
@keyframes fade2 {
    0% {
        opacity:  0.7 /* Começa totalmente opaco */
    }
    20% {
        opacity: 0.3;
    }
    50% {
        opacity: 0.5; /* Diminui para meio opaco */
    }
    100% {
        opacity: 0.7; /* Retorna para totalmente opaco */
    }
}

body.dark .experience .surname span {
    font-family: 'Orbitron', sans-serif;
    color: #ffffff;
    margin-top: -2rem;
    gap: 50px;
    font-size: 150px; /* Tamanho responsivo */
    transform: rotate(-90deg);
    line-height: 1;
}
body.light .experience .surname span {
    font-family: 'Orbitron', sans-serif;
    color: #000000;
    margin-top: -2rem;
    gap: 50px;
    font-size: 150px; /* Tamanho responsivo */
    transform: rotate(-90deg);
    line-height: 1;
}

.chara {
    width: 100%;
    max-width: 500px;
    height: auto;
    margin-top: -33rem;
    margin-right: auto; /* Coloca a imagem à direita no layout */
    margin-left: 1rem;
}
body.dark .chara-fade{
    position: absolute; /* Posiciona o fade na parte inferior da imagem */
    bottom: 0; /* Alinha na parte inferior */
    top: 506px;
    left: 151px; /* Alinha à esquerda */
    right: 0; /* Alinha à direita */
    height: 100px; /* Ajuste a altura conforme necessário */
    width: 260px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgb(15, 15, 15) 100%); /* Ajuste a cor para combinar com seu design */
    pointer-events: none; /* Permite que eventos de mouse passem para a imagem */
}
body.light .chara-fade{
    position: absolute; /* Posiciona o fade na parte inferior da imagem */
    bottom: 0; /* Alinha na parte inferior */
    top: 506px;
    left: 151px; /* Alinha à esquerda */
    right: 0; /* Alinha à direita */
    height: 100px; /* Ajuste a altura conforme necessário */
    width: 260px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%); /* Ajuste a cor para combinar com seu design */
    pointer-events: none; /* Permite que eventos de mouse passem para a imagem */
}

body.dark .historia {
    border-left: 3px solid #e6e6e6;
    border-bottom: 3px solid #e6e6e6;
    max-width: 100rem;
    width: 95%; /* Garante que a seção skills ocupe 100% da largura disponível */
    margin-top: 8rem; /* Adiciona espaço entre o título e a seção de habilidades */
    padding: 1rem; /* Adiciona preenchimento interno */
}
body.light .historia {
    border-left: 3px solid #1b1b1b;
    border-bottom: 3px solid #1b1b1b;
    max-width: 100rem;
    width: 95%; /* Garante que a seção skills ocupe 100% da largura disponível */
    margin-top: 8rem; /* Adiciona espaço entre o título e a seção de habilidades */
    padding: 1rem; /* Adiciona preenchimento interno */
}
body.dark .historia2 {
    border-right: 3px solid #e6e6e6;
    border-bottom: 3px solid #e6e6e6;
    max-width: 100rem;
    width: 95%; /* Garante que a seção skills ocupe 100% da largura disponível */
    margin-top: 0rem; /* Adiciona espaço entre o título e a seção de habilidades */
    padding: 1rem; /* Adiciona preenchimento interno */
}
body.light .historia2 {
    border-right: 3px solid #1b1b1b;
    border-bottom: 3px solid #1b1b1b;
    max-width: 100rem;
    width: 95%; /* Garante que a seção skills ocupe 100% da largura disponível */
    margin-top: 0rem; /* Adiciona espaço entre o título e a seção de habilidades */
    padding: 1rem; /* Adiciona preenchimento interno */
}
body.dark .historia3 {
    border-left: 3px solid #e6e6e6;
    max-width: 100rem;
    width: 95%; /* Garante que a seção skills ocupe 100% da largura disponível */
    margin-top: 0rem; /* Adiciona espaço entre o título e a seção de habilidades */
    margin-bottom: 5rem;
    padding: 1rem; /* Adiciona preenchimento interno */
    text-align: left; /* Alinha o texto à esquerda */
}
body.light .historia3 {
    border-left: 3px solid #1b1b1b;
    max-width: 100rem;
    width: 95%; /* Garante que a seção skills ocupe 100% da largura disponível */
    margin-top: 0rem; /* Adiciona espaço entre o título e a seção de habilidades */
    margin-bottom: 5rem;
    padding: 1rem; /* Adiciona preenchimento interno */
    text-align: left; /* Alinha o texto à esquerda */
}
.historia .title {
    background: linear-gradient(to right, rgb(212, 33, 33), rgb(255, 174, 0)); /* Degradê de azul para roxo */
    -webkit-background-clip: text; /* Clipa o fundo para o texto no Safari */
    -webkit-text-fill-color: transparent; /* Torna o preenchimento do texto transparente */
    font-size: 53px;
}
.arrow-container {
    position: fixed; /* Fica fixo na tela */
    bottom: 20px; /* Distância do fundo da página */
    left: 50%; /* Centraliza horizontalmente */
    transform: translateX(-50%); /* Corrige o alinhamento */
    text-align: center; /* Centraliza o conteúdo dentro */
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0); /* Posição original */
    }
    40% {
        transform: translateY(-10px); /* Salto para cima */
    }
    60% {
        transform: translateY(-5px); /* Salto intermediário */
    }
}

.arrow-down {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 15px solid rgba(58, 58, 58, 0.7);
    transition: opacity 0.5s ease;
    opacity: 1;
    z-index: 1000;
    animation: bounce 1s infinite; /* Adiciona a animação de bounce */
}

.tooltip {
    display: none; /* Inicialmente escondido */
    position: absolute; /* Posiciona em relação à seta */
    bottom: 40px; /* Distância da seta */
    left: 50%; /* Centraliza horizontalmente */
    transform: translateX(-50%); /* Corrige o alinhamento */
    background-color: transparent; /* Fundo do tooltip */
    color: white; /* Cor do texto */
    padding: 5px; /* Espaçamento interno */
    border-radius: 5px; /* Cantos arredondados */
    width: 100px;
    font-weight: 400;
    font-size: 12px; /* Tamanho da fonte */
}

.arrow-container:hover .tooltip {
    display: block; /* Mostra o tooltip ao passar o mouse */
}
.historia-item {
    height: 20rem;
}
.historia-item h2{
    margin-top: 20px;
    font-size: 40px;
    text-align: left;
}
.historia2 h2{
    text-align: right;
}
body.dark .historia-item h3{
    color:#cfcfcf;
    margin-top: -20px;
    max-width: 900px;
    font-size: 24px;
    text-align: left;
}
body.light .historia-item h3{
    color:#1b1b1b;
    margin-top: -20px;
    max-width: 900px;
    font-size: 24px;
    text-align: left;
}
body.light .title .name {
    font-family: 'Orbitron', sans-serif;
    font-weight: 900;
    position: relative; /* Necessário para o efeito */
    top: -430px;
    left: -8rem;
    color: rgb(255, 255, 255); /* Cor do texto com transparência */
    font-size: 150px; /* Tamanho da fonte */
    overflow: hidden; /* Oculta o excesso de texto */
    text-shadow: 
        2px 2px 0 #ff5100, /* Laranja - borda */
        -2px -2px 0 #ff5100, /* Borda */
        2px -2px 0 #ff5100, /* Borda */
        -2px 2px 0 #ff5100; /* Borda */
}
body.dark .title .name {
    font-family: 'Orbitron', sans-serif;
    font-weight: 900;
    position: relative; /* Necessário para o efeito */
    top: -430px;
    left: -8rem;
    color: rgba(15, 15, 15, 1); /* Cor do texto com transparência */
    font-size: 150px; /* Tamanho da fonte */
    overflow: hidden; /* Oculta o excesso de texto */
    text-shadow: 
        2px 2px 0 #ff5100, /* Laranja - borda */
        -2px -2px 0 #ff5100, /* Borda */
        2px -2px 0 #ff5100, /* Borda */
        -2px 2px 0 #ff5100; /* Borda */
}
body.light .title .name2 {
    font-family: 'Orbitron', sans-serif;
    font-weight: 900;
    position: relative; /* Necessário para o efeito */
    top: -430px;
    left: 13rem;
    color: rgb(255, 255, 255); /* Cor do texto com transparência */
    font-size: 150px; /* Tamanho da fonte */
    overflow: hidden; /* Oculta o excesso de texto */
    z-index: 1; /* Certifique-se de que o texto esteja acima do glitch */
    text-shadow: 
        2px 2px 0 #ff5100, /* Laranja - borda */
        -2px -2px 0 #ff5100, /* Borda */
        2px -2px 0 #ff5100, /* Borda */
        -2px 2px 0 #ff5100; /* Borda */
}
body.dark .title .name2 {
    font-family: 'Orbitron', sans-serif;
    font-weight: 900;
    position: relative; /* Necessário para o efeito */
    top: -430px;
    left: 13rem;
    color: rgba(15, 15, 15, 1); /* Cor do texto com transparência */
    font-size: 150px; /* Tamanho da fonte */
    overflow: hidden; /* Oculta o excesso de texto */
    z-index: 1; /* Certifique-se de que o texto esteja acima do glitch */
    text-shadow: 
        2px 2px 0 #ff5100, /* Laranja - borda */
        -2px -2px 0 #ff5100, /* Borda */
        2px -2px 0 #ff5100, /* Borda */
        -2px 2px 0 #ff5100; /* Borda */
}
body.light .title .name3 {
    font-family: 'Orbitron', sans-serif;
    font-weight: 900;
    position: relative; /* Necessário para o efeito */
    top: -470px;
    left: 25rem;
    color: rgb(0, 0, 0);
    font-size: 150px; /* Tamanho da fonte */
    animation: fade 1s infinite; /* Adiciona a animação de opacidade */
}
body.dark .title .name3 {
    font-family: 'Orbitron', sans-serif;
    font-weight: 900;
    position: relative; /* Necessário para o efeito */
    top: -470px;
    left: 25rem;
    color: white;
    font-size: 150px; /* Tamanho da fonte */
    animation: fade 1s infinite; /* Adiciona a animação de opacidade */
}
body.dark .title .name3 span{
    animation: fade 0.5s infinite; /* Adiciona a animação de opacidade */
}
@keyframes fade {
    0% {
        opacity:  0.7 /* Começa totalmente opaco */
    }
    50% {
        opacity: 0.5; /* Diminui para meio opaco */
    }
    100% {
        opacity: 0.7; /* Retorna para totalmente opaco */
    }
}
.title .divider {
    height: 0px;
}
.undertext-div{
    position: relative;
    top: -620px;
    width: 370px;
    text-align: left;
}
body.dark .title .undertext {
    font-family: 'Inter-Bureau', sans-serif;
    font-weight: 900;
    width: 0px;
    color: #cfcfcf;
    font-weight: 500;
    font-size: 25px;
}
body.light .title .undertext {
    color: #525252;
    font-weight: 500;
    font-size: 18px;
}
body.dark .title .img {
    width: 1.5rem;
    height: auto;
    display: flex;
    justify-content: center;
    overflow: hidden;
    object-fit: fill;
    filter: invert(100%) grayscale(100%); /* Inverte as cores dos ícones */
    transition: filter 0.5s ease, transform 0.5s ease;
}
body.light .title .img {
    width: 1.5rem;
    height: auto;
    display: flex;
    justify-content: center;
    overflow: hidden;
    object-fit: fill;
    filter: invert(0%) grayscale(100%); /* Inverte as cores dos ícones */
    transition: filter 0.5s ease, transform 0.5s ease;
}

.title .img:hover {
    filter: grayscale(0%) invert(0%) sepia(100%) saturate(500%); /* Aplica efeito laranja no hover */
    transform: scale(1.5); /* Pode adicionar um leve aumento ao passar o mouse */
}
body.light .title .img:hover {
    filter: grayscale(0%) invert(0); /* Converte para branco ao passar o mouse */
    transform: scale(1.5); /* Aumenta o tamanho da imagem */
}

.title .buttons {
    display: flex;
    list-style: none;
    margin-top: -600px;
    margin-bottom: 0;
    margin-left: -20px;
    padding: 0 0;
    font-size: 14px;
}
.title .buttons a {
    color: white;
    display: block;
    padding: 1rem;
    text-decoration: none;
}
body.light .buttons a {
    color: #1b1b1b;
}
@media (max-width: 768px) {
    body.dark .projetos-ir {
        text-decoration: none;
        font-size:18px;
        top: -1775px;
        left: 645px;
        width: 120px;
        height: 25px;
    }
    .projetos-ir .text {
        font-size: 17px;
    }
    body.lihght .projetos-ir {
        text-decoration: none;
        font-size:18px;
        top: -2010px;
        left: 640px;
        width: 120px;
        height: 25px;
    }
    body.dark .contact-ir {
        text-decoration: none;
        font-size:18px;
        top: -2350px;
        left: 785px;
        width: 120px;
        height: 25px;
    }
    .contact-ir .text {
        font-size: 17px;
    }
    body.lihght .contact-ir {
        text-decoration: none;
        font-size:18px;
        top: -2350px;
        left: 785px;
        width: 120px;
        height: 25px;
    }
    .experience {
        display: flex;
        flex-direction: column;
        align-items: center; /* Centraliza o conteúdo horizontalmente */
        gap: 1rem; /* Espaço entre os elementos */
    }

    /* Flex container para imagem e surname */
    .experience .chara,
    .experience .surname {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0; /* Remove margens no mobile */
        flex-direction: row; /* Alinha elementos lado a lado */
    }
    .chara{
        margin-top: -25rem!important;
    }
    .experience .title {
        font-family: 'Orbitron', sans-serif;
        color: #ff5100;
        font-size: 40px;
        margin: 1rem 1rem;
        width: 500px;
    }
    .experience .surname {
        display: flex;
        flex-direction: column;
        animation: fade2 1s infinite;
    }
    body.dark .experience .surname span {
        font-family: 'Orbitron', sans-serif;
        color: #ffffff;
        margin-right: 18rem;
        margin-top: 0rem;
        gap: 50px;
        font-size: 90px;
        transform: rotate(-90deg);
        line-height: 1;
    }
    body.light .experience .surname span {
        font-family: 'Orbitron', sans-serif;
        color: #000000;
        margin-right: 18rem;
        margin-top: 0rem;
        gap: 50px;
        font-size: 90px;
        transform: rotate(-90deg);
        line-height: 1;
    }
    .chara-fade{
        margin-left: -10px;
    }
    .about{
        margin-top: 0rem!important;
        font-size: 20px!important;
        width: 100%;
        max-width: 400px;
    }
    .chara {
        width: 400px;
        height: auto;
        margin-top: -13rem;
        margin-right: auto;
        margin-left: 3rem;
    }
    .carousel {
        flex-direction: column; /* Altere a direção para coluna em dispositivos móveis */
        align-items: center; /* Centraliza os itens do carrossel */
    }

    .carousel-item {
        width: 100%; /* Cada item ocupa 100% da largura do carrossel */
        margin: 0; /* Remove margem para que ocupe toda a largura */
    }

    .contentContainer {
        padding: 10px; /* Ajusta o preenchimento do contêiner */
    }

    .indicator {
        left: 0; /* Centraliza o indicador */
        right: 0; /* Centraliza o indicador */
    }
    .titletext{
        width: 300px!important;
        top: -30px!important;
        left: -5px!important;
        font-size: 15px!important;
    }
    .dev01{
        font-size: 150px!important;
        left: -60px!important;
        top: -30px!important;
    }
    .dev{
        left: -45px!important;
        font-size: 20px!important;
        top: -120px!important;
    }
    .description{
        width: 320px!important;
        left: 2px!important;
        font-size: 12px!important;
        top: -20px!important;
    }
    .robozao{
        top: 130px!important;
        left: 935px!important;

    }
    .robozao-img{
        width: 130px !important;
        height: auto !important;
    }
    .robozao-fade{
        top: 30px!important;
        left: 25px!important;
        width: 105px!important;
    }

    .dev02{
        font-size: 150px!important;
        left: 200px!important;
        top: -625px!important;
    }
    .dev2{
        left: 155px!important;
        font-size: 20px!important;
        top: -710px!important;
    }
    .description2{
        width: 320px!important;
        left: 245px!important;
        font-size: 12px!important;
        top: -610px!important;
    }
    .titletext2{
        width: 320px!important;
        left: 245px!important;
        font-size: 15px!important;
        top: -610px!important;
    }


    .dev03{
        font-size: 150px!important;
        left: 440px!important;
        top: -1220px!important;
    }
    .dev3{
        font-size: 20px!important;
        left: 390px!important;
        top: -1310px!important;
    }
    .description3{
        width: 320px!important;
        left: 485px!important;
        font-size: 12px!important;
        top: -1205px!important;
    }
    .titletext3{
        width: 320px!important;
        left: 485px!important;
        font-size: 15px!important;
        top: -1205px!important;
    }



    .dev04{
        font-size: 150px!important;
        left: 675px!important;
        top: -1810px!important;
    }
    .dev4{
        font-size: 20px!important;
        left: 620px!important;
        top: -1900px!important;
    }
    .description4{
        width: 320px!important;
        left: 725px!important;
        font-size: 12px!important;
        top: -1800px!important;
    }
    .titletext4{
        width: 320px!important;
        left: 725px!important;
        font-size: 15px!important;
        top: -1795px!important;
    }

    .dev05{
        font-size: 150px!important;
        left: 830px!important;
        top: -2375px!important;
    }
    .dev5{
        font-size: 20px!important;
        left: 760px!important;
        top: -2465px!important;
    }
    .description5{
        width: 300px!important;
        left: 835px!important;
        font-size: 12px!important;
        top: -2400px!important;
    }
    .titletext5{
        width: 320px!important;
        left: 842px!important;
        font-size: 15px!important;
        top: -2370px!important;
    }


    body.dark .history-line {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
        border-top: 2px dotted rgba(255, 255, 255, 0.5);
        padding-top: 0px;
        margin-top: -20px;
        gap: 60px;
    }
    .indicator{
        left: -140px;
        top: -90px;
    }
    body.light .history-line {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
        border-top: 2px dotted rgba(0, 0, 0, 0.5);
        padding-top: 0px;
        margin-top: 30px;
        gap: 60px;
    }

    body.dark .slide-number {
        position: absolute;
        font-family: 'Orbitron', sans-serif;
        color: rgba(15, 15, 15, 1);
        font-size: 28px;
        text-shadow: 1px 1px 0 #8f2e01, -1px -1px 0 #8f2e01, 1px -1px 0 #8f2e01, -1px 1px 0 #8f2e01;
        top: -60px;
        left: -10px;
        text-align: center;
        white-space: nowrap;
    }
    body.light .slide-number {
        position: absolute;
        font-family: 'Orbitron', sans-serif;
        color: rgb(255, 255, 255);
        font-size: 28px;
        text-shadow: 1px 1px 0 #8f2e01, -1px -1px 0 #8f2e01, 1px -1px 0 #8f2e01, -1px 1px 0 #8f2e01;
        top: -60px;
        left: -10px;
        text-align: center;
        white-space: nowrap;
    }
    body.dark .slide-number2 {
        position: absolute;
        font-family: 'Orbitron', sans-serif;
        color: rgb(95, 95, 95);
        font-size: 8px;
        top: -20px;
        left: -10px;
        text-align: center;
        white-space: nowrap;
    }
    body.light .slide-number2 {
        position: absolute;
        font-family: 'Orbitron', sans-serif;
        color: rgb(95, 95, 95);
        font-size: 10px;
        top: -20px;
        left: -10px;
        text-align: center;
        white-space: nowrap;
    }

    body.dark .skills .title {
        font-family: 'Orbitron', sans-serif;
        position: relative;
        top: 120px;
        left: 0px;
        background: white;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 40px;
        animation: fade 0.5s infinite;
    }
    body.light .skills .title {
        font-family: 'Orbitron', sans-serif;
        position: relative;
        top: 120px;
        left: 0px;
        background: rgb(7, 7, 7);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 40px;
        animation: fade 0.5s infinite;
    }

    body.dark .skills .title-en {
        font-family: 'Orbitron', sans-serif;
        position: relative;
        top: 120px;
        left: -70px;
        background: white;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 40px;
        animation: fade 0.5s infinite;
    }
    body.light .skills .title-en {
        font-family: 'Orbitron', sans-serif;
        position: relative;
        top: 120px;
        left: -70px;
        background: rgb(7, 7, 7);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 40px;
        animation: fade 0.5s infinite;
    }
    .carousel-item {
        max-width: 700px;
        height: 690px;
        background: transparent;
        color: white;
        margin: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 48px;
        opacity: 0;
        transform: translateY(20px);
        transition: opacity 0.5s ease, transform 0.5s ease;
    }

    body.dark .title .name,
    body.light .title .name,
    body.light .title .name2,
    body.dark .title .name2,
    body.light .title .name3,
    body.dark .title .name3 {
        font-size: 55px;
        text-align: center; /* Centraliza o texto */
        margin-left: auto; /* Centraliza o elemento em relação ao contêiner */
        left: 0; /* Reseta a posição à esquerda */
    }

    /* Adiciona margens para os nomes conforme necessário para espaçamento */
    body.light .title .name,
    body.dark .title .name {
        margin-bottom: 20px; /* Adiciona espaçamento inferior */
    }
    body.light .title .name2,
    body.dark .title .name2,
    body.light .title .name3,
    body.dark .title .name3 {
        margin-bottom: 20px; /* Adiciona espaçamento inferior */
    }
    body.light .title .name2,
    body.dark .title .name2,
    body.light .title .name,
    body.dark .title .name{
        top: -550px;
    }
    body.light .title .name3,
    body.dark .title .name3{
        top: -550px;
    }
    .undertext-div {
        top: -140px;
        width: 100%; /* Altera a largura para 100% para ocupar toda a largura disponível */
        text-align: center; /* Centraliza o texto */
        margin-top: 20px; /* Adiciona um espaçamento superior, se necessário */
    }
    body.dark .title .undertext-div .undertext{
        margin-left: auto; /* Centraliza o elemento em relação ao contêiner */
        left: 0;
    }
    canvas {
        position: relative;
        left: 20px;
        top: 130px !important;
    }
    body.dark .historia-item h3 {
        font-size: 18px;
    }
    body.light .historia-item h3 {
        font-size: 18px;
    }
    .historia .h2{
        text-align: center; /* Alinha o texto à esquerda */
    }
    .title-container {
        flex-direction: column; /* Coloca os itens em coluna em telas menores */
        align-items: center; /* Centraliza o conteúdo no eixo horizontal */
        text-align: center; /* Centraliza o texto no contêiner */
    }
    .skills {
        text-align: center;
    }
    .experience {
        text-align: center;
    }
    .experience .timeline{
        text-align: left;
    }
    .historia {
        text-align: center;
    }
    .title {
        margin-left: 0; /* Remove a margem esquerda no mobile */
        margin-right: 0; /* Remove a margem direita no mobile */
        width: 100%; /* Permite que o título ocupe 100% da largura */
    }
    .title .name {
        font-size: 35px;
    }
    .title .divider {
        height: 20px;
    }
    .title.light .undertext {
        color: #1b1b1b;
        font-size: 14px;
    }
    .title .buttons {
        display: flex; /* Mantém os botões lado a lado */
        list-style: none;
        margin-top: -120px; /* Ajuste conforme necessário */
        margin-left: 10px;
        margin-bottom: 0;
        padding: 0;
        font-size: 14px;
        justify-content: center; /* Centraliza os botões horizontalmente */
    }
    .title .buttons a {
        margin: 0.5rem; /* Espaçamento entre os botões */
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
/* Adicione isso ao seu Home.css */

body.dark .timeline {
    border-left: 2px solid #cfcfcf; /* Linha vertical da timeline */
    padding-left: 1.5rem; /* Espaço à esquerda */
    position: relative; /* Necessário para posicionar itens */
}
body.light .timeline {
    border-left: 2px solid #1b1b1b; /* Linha vertical da timeline */
    padding-left: 1.5rem; /* Espaço à esquerda */
    position: relative; /* Necessário para posicionar itens */
}

.timeline-item {
    position: relative;
    margin-bottom: 2rem; /* Espaçamento entre itens */
}

.timeline-item::before {
    content: '';
    position: absolute;
    left: -31px; /* Posiciona o ponto à esquerda da linha */
    top: 0;
    width: 12px; /* Largura do ponto */
    height: 12px; /* Altura do ponto */
    background: #3498db; /* Cor do ponto */
    border-radius: 50%; /* Faz o ponto ser circular */
    z-index: 1; /* Coloca o ponto acima do conteúdo */
}

.timeline-item h3 {
    font-size: 20px; /* Tamanho do título */
    margin: 0;
    font-weight: bold;
}

.timeline-item .date {
    font-size: 14px; /* Tamanho da data */
    color: #666; /* Cor da data */
    margin-bottom: 0.5rem; /* Espaçamento abaixo da data */
}

.timeline-item p {
    margin: 0; /* Remove margens */
    color: #333; /* Cor do texto da descrição */
}

canvas {
    position: relative;
    left: 20px;
    top: 80px;
    height: 32rem !important;
}
.canvas-container {
    position: relative;
    overflow: hidden;
}

.canvas-container canvas {
    width: 100%;
    height: 100%;
}

.canvas-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.7) 100%);
    pointer-events: none; /* Permite interações com o canvas */
}

.cards{
    margin-top: -100px;
    margin-bottom: 100px;
}
.robozao{
    top: -15px;
    left: 600px;
    position: absolute;
    width: 10px;
    height: auto;
}
.robozao-img{
    width: 500px;
    height: auto;
}
body.dark .robozao-fade {
    position: absolute; /* Posiciona o fade na parte inferior da imagem */
    bottom: 0; /* Alinha na parte inferior */
    top: 400px;
    left: 105px; /* Alinha à esquerda */
    right: 0; /* Alinha à direita */
    height: 100px; /* Ajuste a altura conforme necessário */
    width: 395px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(15, 15, 15, 1) 100%); /* Ajuste a cor para combinar com seu design */
    pointer-events: none; /* Permite que eventos de mouse passem para a imagem */
}
body.light .robozao-fade {
    position: absolute; /* Posiciona o fade na parte inferior da imagem */
    bottom: 0; /* Alinha na parte inferior */
    top: 400px;
    left: 105px; /* Alinha à esquerda */
    right: 0; /* Alinha à direita */
    height: 100px; /* Ajuste a altura conforme necessário */
    width: 395px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%); /* Ajuste a cor para combinar com seu design */
    pointer-events: none; /* Permite que eventos de mouse passem para a imagem */
}