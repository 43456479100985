.Portfolio {
    margin-bottom: 2rem;
    max-width: 60rem;
    margin: 0 auto;
    font-family: var(--font-sans);
    margin-top: 4rem;
    line-height: 1.3;
    font-family: Whitney, sans-serif;
    font-weight: 800;
    display: flex;
    flex-direction: column; /* Muda para coluna por padrão */
    align-items: center; /* Centraliza o conteúdo */
}

.port-title{
    margin-bottom: 5rem;
    width: 100%;
    height: 10rem;
}
.port-title h1 {
    font-family: 'Orbitron', sans-serif;
    font-size: 60px;
    margin-bottom: 0.2rem; /* Ajuste a margem inferior do h1 */
    animation: fade 0.5s infinite; /* Adiciona a animação de opacidade */
}
@keyframes fade {
    0% {
        opacity:  0.7 /* Começa totalmente opaco */
    }
    50% {
        opacity: 0.5; /* Diminui para meio opaco */
    }
    100% {
        opacity: 0.7; /* Retorna para totalmente opaco */
    }
}

body.dark .port-title h2 {
    font-family: 'Inter-Bureau', sans-serif;
    color: rgb(236, 236, 236);
    font-weight: 500;
    font-size: 25px;
    margin-top: 0; /* Remove a margem superior do h2 */
}
body.light .port-title h2 {
    font-family: 'Inter-Bureau', sans-serif;
    color: rgb(0, 0, 0);
    font-weight: 500;
    font-size: 25px;
    margin-top: 0; /* Remove a margem superior do h2 */
}
.port-subtitle {
    position: relative; /* Necessário para o posicionamento do pseudo-elemento */
    overflow: hidden; /* Evita que a imagem ultrapasse os limites do contêiner */
    width: 80%; /* Largura do contêiner */
    height: 500px; /* Ajuste conforme necessário */
    display: flex; /* Para centralizar conteúdo se necessário */
    align-items: center; /* Centraliza verticalmente */
    justify-content: center; /* Centraliza horizontalmente */
    border-radius: 15px; /* Borda arredondada */
    border: 1px solid rgb(41, 41, 41); /* Borda */
    box-shadow: inset 0 0 25px #adadad; /* Sombra */
    text-decoration: none;
}

.port-subtitle::before {
    content: '';
    background-image: url('./assets/backg.png'); /* Substitua pelo caminho da sua imagem */
    background-size: cover; /* Faz a imagem cobrir todo o espaço do elemento */
    background-position: center; /* Centraliza a imagem */
    position: absolute; /* Posiciona o pseudo-elemento sobre o contêiner */
    top: 0; /* Início no topo */
    left: 0; /* Início à esquerda */
    right: 0; /* Largura total */
    bottom: 0; /* Altura total */
    transition: transform 0.5s ease; /* Transição suave para o efeito de zoom */
    z-index: 0; /* Coloca o pseudo-elemento atrás do conteúdo */
    border-radius: 15px;
    border-color: rgb(41, 41, 41);
    border-width: 1px;
    border-style: solid;
    box-shadow: inset 0 0 25px #adadad;
}

.port-subtitle::after {
    content: '';
    position: absolute; /* Posiciona o pseudo-elemento sobre o contêiner */
    top: 0; /* Início no topo */
    left: 0; /* Início à esquerda */
    right: 0; /* Largura total */
    bottom: 0; /* Altura total */
    background-color: rgba(0, 0, 0, 0.4); /* Cor preta com 50% de opacidade */
    z-index: 1; /* Coloca o pseudo-elemento acima da imagem */
    border-radius: 15px;
    border-color: rgb(41, 41, 41);
    border-width: 1px;
    border-style: solid;
    box-shadow: inset 0 0 25px #000000;
}
.port-subtitle:hover::before {
    transform: scale(1.1); /* Aumenta a imagem de fundo quando o mouse passa por cima */
}
.port-subtitle2 {
    margin-top: 5rem;
    position: relative; /* Necessário para o posicionamento do pseudo-elemento */
    overflow: hidden; /* Evita que a imagem ultrapasse os limites do contêiner */
    width: 80%; /* Largura do contêiner */
    height: 500px; /* Ajuste conforme necessário */
    display: flex; /* Para centralizar conteúdo se necessário */
    align-items: center; /* Centraliza verticalmente */
    justify-content: center; /* Centraliza horizontalmente */
    border-radius: 15px; /* Borda arredondada */
    border: 1px solid rgb(41, 41, 41); /* Borda */
    box-shadow: inset 0 0 25px #adadad; /* Sombra */
    text-decoration: none;
}

.port-subtitle2::before {
    content: '';
    background-image: url('./assets/dc.png'); /* Substitua pelo caminho da sua imagem */
    background-size: cover; /* Faz a imagem cobrir todo o espaço do elemento */
    background-position: center; /* Centraliza a imagem */
    position: absolute; /* Posiciona o pseudo-elemento sobre o contêiner */
    top: 0; /* Início no topo */
    left: 0; /* Início à esquerda */
    right: 0; /* Largura total */
    bottom: 0; /* Altura total */
    transition: transform 0.5s ease; /* Transição suave para o efeito de zoom */
    z-index: 0; /* Coloca o pseudo-elemento atrás do conteúdo */
    border-radius: 15px;
    border-color: rgb(41, 41, 41);
    border-width: 1px;
    border-style: solid;
    box-shadow: inset 0 0 25px #adadad;
}

.port-subtitle2::after {
    content: '';
    position: absolute; /* Posiciona o pseudo-elemento sobre o contêiner */
    top: 0; /* Início no topo */
    left: 0; /* Início à esquerda */
    right: 0; /* Largura total */
    bottom: 0; /* Altura total */
    background-color: rgba(0, 0, 0, 0.4); /* Cor preta com 50% de opacidade */
    z-index: 1; /* Coloca o pseudo-elemento acima da imagem */
    border-radius: 15px;
    border-color: rgb(41, 41, 41);
    border-width: 1px;
    border-style: solid;
    box-shadow: inset 0 0 25px #000000;
}
.port-subtitle2:hover::before {
    transform: scale(1.1); /* Aumenta a imagem de fundo quando o mouse passa por cima */
}
.overlay-text {
    top: 190px;
    left: -280px;
    position: relative; /* Posiciona o texto em relação ao contêiner pai */
    color: rgb(201, 201, 201); /* Cor do texto */
    font-size: 25px; /* Tamanho do texto */
    font-weight: 500; /* Negrito */
    z-index: 2; /* Garante que o texto fique acima da imagem e da camada de sombra */
    text-align: center;
}
.overlay-text2 {
    top: 190px;
    left: -240px;
    position: relative; /* Posiciona o texto em relação ao contêiner pai */
    color: rgb(201, 201, 201); /* Cor do texto */
    font-size: 25px; /* Tamanho do texto */
    font-weight: 500; /* Negrito */
    z-index: 2; /* Garante que o texto fique acima da imagem e da camada de sombra */
    text-align: center;
}
@media (max-width: 768px) {
    .port-title{
        width: 100%;
        height: 14rem;
        text-align: center;
    }
    .overlay-text {
        top: 190px;
        left: -50px;
        position: relative; /* Posiciona o texto em relação ao contêiner pai */
        color: white; /* Cor do texto */
        font-size: 25px; /* Tamanho do texto */
        font-weight: bold; /* Negrito */
        z-index: 2; /* Garante que o texto fique acima da imagem e da camada de sombra */
        text-align: center;
    }
    .overlay-text2 {
        top: 190px;
        left: -20px;
        position: relative; /* Posiciona o texto em relação ao contêiner pai */
        color: white; /* Cor do texto */
        font-size: 25px; /* Tamanho do texto */
        font-weight: bold; /* Negrito */
        z-index: 2; /* Garante que o texto fique acima da imagem e da camada de sombra */
        text-align: center;
    }
}