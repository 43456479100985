/* src/components/NotFound.css */

.not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 4rem;
    height: 70vh;
    text-align: center;
  }
  
  .not-found h1 {
    font-family: 'Orbitron', sans-serif;
    font-size: 3rem;
    color: #ff2600; /* Vermelho para destacar o erro */
  }
  
  .not-found p {
    font-family: 'Inter-Bureau', sans-serif;
    font-size: 1.25rem;
  }
  .not-found a {
    font-family: 'Inter-Bureau', sans-serif;
    font-weight: 500;
    margin-top: 1rem;
    color: #ff7300;
    text-decoration: none;
  }
  
body.dark .error {
    font-weight: 600;
    font-family: 'Orbitron', sans-serif;
    background: rgb(255, 255, 255);
    -webkit-background-clip: text; /* Clipa o fundo para o texto no Safari */
    -webkit-text-fill-color: transparent; /* Torna o preenchimento do texto transparente */
    font-size: 150px;
    animation: fade 0.5s infinite; /* Adiciona a animação de opacidade */
}
body.light .error {
  font-weight: 600;
  font-family: 'Orbitron', sans-serif;
  background: rgb(0, 0, 0);
  -webkit-background-clip: text; /* Clipa o fundo para o texto no Safari */
  -webkit-text-fill-color: transparent; /* Torna o preenchimento do texto transparente */
  font-size: 150px;
  animation: fade 0.5s infinite; /* Adiciona a animação de opacidade */
}
@keyframes fade {
    0% {
        opacity:  0.7 /* Começa totalmente opaco */
    }
    50% {
        opacity: 0.5; /* Diminui para meio opaco */
    }
    100% {
        opacity: 0.7; /* Retorna para totalmente opaco */
    }
}