

html, body {
  scroll-behavior: smooth;
  overflow-x: hidden;
  height: 100%; /* Garante que o body ocupe 100% da altura da janela */
  margin: 0; /* Remove margens padrão */
}
html, body, a, button, input, select, textarea {
  cursor: none !important;
}
* {
  cursor: none; /* Adiciona o estilo para todos os elementos */
}

/* CSS */
/* Estilo padrão do cursor personalizado */
body.dark .custom-cursor {
  width: 25px;
  height: 25px;
  border: 2px solid white;
  border-radius: 50%;
  position: fixed;
  pointer-events: none;
  z-index: 9999;
  transition: transform 0.1s ease, width 0.2s ease, height 0.2s ease;
}

body.light .custom-cursor {
  width: 25px;
  height: 25px;
  border: 2px solid black;
  border-radius: 50%;
  position: fixed;
  pointer-events: none;
  z-index: 9999;
  transition: transform 0.1s ease, width 0.2s ease, height 0.2s ease;
}

body.dark .custom-cursor::before {
  content: '';
  width: 8px;
  height: 8px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: background-color 0.3s ease;
}
body.light .custom-cursor::before {
  content: '';
  width: 8px;
  height: 8px;
  background-color: black;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: background-color 0.3s ease;
}

/* Estilo expandido do cursor para elementos clicáveis */
body.dark .custom-cursor.expand {
  background-color: rgba(255, 255, 255, 0.5); /* Fundo semi-transparente */
  width: 60px;  /* Aumenta o tamanho do círculo externo */
  height: 60px;
  opacity: 1; /* Torna visível ao expandir */
}
body.light .custom-cursor.expand {
  background-color: rgba(68, 68, 68, 0.5); /* Fundo semi-transparente */
  width: 60px;  /* Aumenta o tamanho do círculo externo */
  height: 60px;
  opacity: 1; /* Torna visível ao expandir */
}



body.dark {
  overflow-x: hidden;
  background-color: rgb(15, 15, 15);
  color: white;
  margin: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.light {
  overflow-x: hidden;
  background-color: rgb(243, 243, 243); /* Degradê de cima para baixo */
  color: #1b1b1b;
  margin: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (max-width: 768px) {
  .custom-cursor {
    background-color: transparent!important;
    border: transparent !important;
  }
  .custom-cursor::before {
    background-color: transparent !important;
    border: transparent !important;
  }
  .custom-cursor.expand {
    background-color: transparent !important;
    border: transparent !important;
  }
}
